export class PreviredServiceTool {
  constructor(kongClient, previredUrl) {
    this.kongClient = kongClient;
    this.baseUrl = `${previredUrl.replace(/\/+$/, "")}/api/v2`;
  }

  async prepareConfig() {
    const token = await this.kongClient.getToken();
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    };
  }

  async checkBlockedRut(headersData) {
    const config = await this.prepareConfig();
    const url = `${this.baseUrl}/validate/rut`;
    const queryParams = new URLSearchParams(headersData).toString();
    const finalUrl = `${url}?${queryParams}`;
    const response = await fetch(finalUrl, {
      method: "GET",
      headers: config
    });

    const data = await response.json();
    return data;
  }
}

export class DummyPreviredServiceTool {
  constructor(previredUrl) {
    this.baseUrl = `${previredUrl.replace(/\/+$/, "")}/api/v2`;
  }
  async checkBlockedRut() {
    return Promise.resolve({ blocked: false });
  }
}
